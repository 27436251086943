
import { AssociateStatus, IAssociate } from "@/api";
import Vue from "vue";

export default Vue.extend({
  name: "MemeberCounts",
  props: {},
  computed: {
    associates: function (): IAssociate[] {
      return this.$store.state.company?.associates;
    },
    memberTotal: function (): number {
      return this.associates?.length;
    },
    pendingInvites: function (): number {
      return this.associates?.filter((a: IAssociate) => {
        return (
          a.status === AssociateStatus.InviteSent ||
          a.status === AssociateStatus.ReinviteSent
        );
      })?.length;
    },
    seatsAvailable: function (): number {
      const available =
        this.$store.state.company?.account?.license_info?.total_users_limit -
        this.associates?.length;
      return available < 0 ? 0 : available;
    },
  },
  methods: {},
});
