
import Vue from "vue";

export default Vue.extend({
  name: "OwnershipTransferSuccessDialog",
  props: {
    dialog: Boolean,
    newOwner: Object,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
  },
});
