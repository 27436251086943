
import Vue from "vue";
import {
  AssociateRoles,
  associates,
  AssociateStatus,
  CompanyStatus,
  IAssociate,
  multiApiOperations,
} from "@/api";
import { Actions } from "@/store/models";
import { getYear, toPascalCase } from "@/utils";
import { permissions } from "@/mixins";
import ConfirmationDialog from "../common/ConfirmationDialog.vue";
import AccountManagementUserDialog from "./AccountManagementUserDialog.vue";
import GssiUserDialog from "./GssiUserDialog.vue";
import UserIcon from "../common/UserIcon.vue";

export default Vue.extend({
  name: "AccountManagementUsers",
  components: {
    ConfirmationDialog,
    AccountManagementUserDialog,
    GssiUserDialog,
    UserIcon,
  },
  mixins: [permissions],
  props: {
    isProAccount: Boolean,
  },
  data() {
    return {
      userDialog: false,
      gssiUserDialog: false,
      deleteUserDialog: false,
      selected: [] as IAssociate[],
      headers: [
        { text: "USERS", value: "user" },
        { text: "ACCOUNT STATUS", value: "status" },
        { text: "ACCESS", value: "permissions" },
        { text: "", value: "action" },
      ],
      getYear: getYear,
      isBeta: process.env.VUE_APP_BETA_VERSION === "true",
      editingUser: {} as IAssociate,
      ownershipTransferDialog: false,
    };
  },

  computed: {
    items: function (): IAssociate[] {
      const associates = this.$store.state.company.associates;
      return associates;
    },
    selectedIds: function (): number[] {
      return this.selected.map((p: { id: number }) => p.id);
    },
    isGssiAccountManagement: function (): boolean {
      return (this as any).isGssiAdmin && !this.$route.params.companyId;
    },
    enableEdit: function (): boolean {
      if ((this as any).isGssiAdmin) {
        // return (
        //   this.isGssiAccountManagement && (this as any).hasGssiUsersPermission
        // );
        return true;
      } else {
        return (this as any).hasAccountPermission;
      }
    },
    reachedUserLimit: function (): boolean {
      return (
        this.$store.state.company?.account?.license_info?.total_users_limit <=
        this.items.length
      );
    },
    suspended: function (): boolean {
      return this.$store.state.company?.status === CompanyStatus.SUSPENDED;
    },
  },
  methods: {
    getPermissions(item: IAssociate) {
      if (!item.roles) return "None";
      // ignore shared projects
      let roles = item.roles.filter((r) => r !== AssociateRoles.SharedProjects);
      roles = roles.map((r) => {
        if (r === AssociateRoles.ManageAccount) {
          return "Account";
        }
        return toPascalCase(r.split("_")[1]);
      }) as any;
      return roles.join(" | ");
    },
    getStatus(item: IAssociate) {
      if (!item.status) return "UNKNOWN";
      return AssociateStatus[item.status];
    },
    getStatusColor(item: IAssociate) {
      if (item.status === AssociateStatus.Active) {
        return "#7bd94b";
      } else if (item.status === AssociateStatus.Deactivated) {
        return "#ec734b";
      }
    },
    async remove() {
      if (!this.selected.length) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please select an user first."
        );
      }
      try {
        await multiApiOperations(associates.delete, this.selectedIds);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        this.$store.dispatch(Actions.GetCompanyAssociates);
        this.selected = [];
      }
    },
    invite() {
      if (this.isGssiAccountManagement) {
        this.gssiUserDialog = true;
      } else {
        this.userDialog = true;
      }
    },
    edit(item: IAssociate) {
      this.editingUser = item;
      if (this.isGssiAccountManagement) {
        this.gssiUserDialog = true;
      } else {
        this.userDialog = true;
      }
    },
    async resendInvite(item: IAssociate) {
      try {
        await associates.reinvite(item.id);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        this.$store.dispatch(Actions.GetCompanyAssociates);
      }
    },
    selectTablerows() {
      if (this.selected.length == 0) {
        this.selected = this.items;
      } else {
        this.selected = [] as IAssociate[];
      }
    },
  },
});
