
import { checkout, companies, ICompany, PaymentStatus } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  components: {},
  name: "OutstandingBalanceDialog",
  props: {
    dialog: Boolean,
    balance: Number,
    ownershipTransferDialog: Boolean,
    deleteAccountDialog: Boolean,
    isDeleteAccount: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    company: function (): ICompany {
      return this.$store.state.company;
    },
    text: function (): string[] {
      let result = [];
      if (this.isDeleteAccount) {
        result.push("Due to the outstanding balance, delete Fusion account");
      } else {
        result.push("Due to the outstanding balance a transfer ownership");
      }
      result.push("cannot be done. Please make a payment and try again.");
      return result;
    },
  },
  methods: {
    async submit() {
      try {
        // pay outstanding balance
        const response = (
          await checkout.payAllInvoiceDues((this as any).companyId)
        ).data;
        if (response[0].payment_status === PaymentStatus.Failed) {
          throw "Your card was declined. Please try again with a different payment card.";
        }

        // refresh company info/status
        const company = (await companies.get(this.company.id)).data[0];
        this.$store.dispatch(Actions.GetCompany, company);

        //display ownership transfer dialog or delete account dialog
        if (this.isDeleteAccount) {
          this.$emit("update:deleteAccountDialog", true);
        } else {
          this.$emit("update:ownershipTransferDialog", true);
        }
        this.close();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    close() {
      this.$emit("update:dialog", false);
    },
  },
  watch: {},
});
