
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmationDialog",
  props: {
    dialog: Boolean,
    icon: String,
    iconBgColor: String,
    title: String,
    text: String,
    btnText: String,
    btnColor: String,
    btnBgColor: String,
    disableCancel: Boolean,
  },
  methods: {
    async onConfirmation() {
      this.$emit("onConfirmation");
      this.closeDialog();
    },
    closeDialog() {
      this.$emit("toggleDialog", false);
      this.$emit("update:dialog", false);
      this.$emit("onCancel");
    },
  },
  watch: {},
});
