
import { companies } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  components: {},
  name: "OwnershipTransferConflictDialog",
  props: {
    dialog: Boolean,
    newOwner: Object,
  },
  data() {
    return {};
  },
  computed: {
    text: function (): string[] {
      return [
        "A transfer request has already been initiated for ",
        "a different owner. Please cancel the existing request",
        "by clicking on the PROCEED button. ",
        // `${this.newOwner.first_name} ${this.newOwner.last_name}`,
      ];
    },
  },
  methods: {
    async submit() {
      try {
        companies.company.cancelTransferOwnershipRequest(
          (this as any).companyId
        );
        this.close()
      } catch (e: any) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    close() {
      this.$emit("update:dialog", false);
    },
  },
  watch: {},
});
