
import Vue from "vue";
import {
  AssociateStatus,
  AssociateType,
  checkout,
  companies,
  IAssociate,
  ICompany,
  ICompanyAccount,
  LicenseType,
} from "@/api";
import Modules from "@/components/accountManagement/AccountManagementModules.vue";
import Storage from "@/components/accountManagement/AccountManagementStorage.vue";
import Users from "@/components/accountManagement/AccountManagementUsers.vue";
import { getDate } from "@/utils";
import CompanyHeader from "@/components/common/gssiAdmin/CompanyHeader.vue";
import OwnershipTransferDialog from "@/components/accountManagement/OwnershipTransferDialog.vue";
import MemberCounts from "@/components/accountManagement/MemberCounts.vue";
import { Actions } from "@/store/models";
import OutstandingBalanceDialog from "@/components/accountManagement/OutstandingBalanceDialog.vue";
import { permissions } from "@/mixins";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";

export default Vue.extend({
  name: "AccountManagement",
  components: {
    // Modules,
    // Storage,
    Users,
    CompanyHeader,
    OwnershipTransferDialog,
    MemberCounts,
    OutstandingBalanceDialog,
    ConfirmationDialog,
  },
  mixins: [permissions],
  data() {
    return {
      isBeta: process.env.VUE_APP_BETA_VERSION === "true",
      ownershipTransferDialog: false,
      outstandingBalanceDialog: false,
      outstandingBalance: 0,
      deleteAccountDialog: false,
      isDeleteAccount: false,
    };
  },
  computed: {
    company: function (): ICompany {
      return this.$store.state.company;
    },
    account: function (): ICompanyAccount {
      return this.$store.state.company?.account;
    },
    details: function (): any {
      const { cao } = this.account || {};
      return [
        { label: "Account Number", text: this.company?.account_number },
        {
          label: "Renewal Date",
          text: getDate(this.company?.renewal_date as any),
        },
        {
          label: "Account Owner",
          text: `${cao?.first_name} ${cao?.last_name}`,
        },
        { label: "Company", text: cao?.company?.name },
      ];
    },
    associates: function (): IAssociate[] {
      return this.$store.state.company?.associates;
    },
    isProAccount: function (): boolean {
      return (
        this.$store.getters.licenseType === LicenseType.Pro ||
        this.$store.getters.licenseType === LicenseType.FreeTrial
      );
    },
    isAdmin: function (): boolean {
      return (
        this.$store.state.associate?.associate_type?.name ===
          AssociateType.CompanyAccountOwner ||
        this.$store.state.associate?.associate_type?.name ===
          AssociateType.GSSIAdmin
      );
    },
    isGSSICompany: function (): boolean {
      return (
        this.company.id === 1 || this.company.gssi_company
      );
    },
    isGSSIAdmin: function (): boolean {
      return (
        this.$store.state.associate?.associate_type?.name ===
        AssociateType.GSSIAdmin
      );
    },
  },
  mounted() {
    // display transfer dialog -> from edit billing view
    if (this.$route.query.transferownership) {
      this.transferOwnership();
    }
  },
  methods: {
    async freeTrial() {
      try {
        await companies.license.freeTrial(this.company.id);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    invoiceHistory() {
      this.$router.push({ path: "invoicehistory" });
    },
    managePlan() {
      this.$router.push({ path: "accountplan/?isPro" });
    },
    billing() {
      this.$router.push({ path: "billing" });
    },
    async getBalanceDue() {
      try {
        this.outstandingBalance = (
          await checkout.getOutstandingDues(this.company.id)
        ).data.total_due;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async transferOwnership() {
      this.isDeleteAccount = false;
      if ((this as any).isGssiAdmin) {
        await this.getBalanceDue();
        if (this.outstandingBalance) {
          this.outstandingBalanceDialog = true;
        } else {
          this.ownershipTransferDialog = true;
        }
      } else {
        this.ownershipTransferDialog = true;
      }
    },
    async triggerDeleteAccount() {
      this.isDeleteAccount = true;
      if (this.isGSSIAdmin) {
        await this.getBalanceDue();
        if (this.outstandingBalance) {
          this.outstandingBalanceDialog = true;
        } else {
          this.deleteAccountDialog = true;
        }
      }
    },
    async deleteAccount() {
      try {
        await companies.deleteCompany(this.company.id);
        this.$router.push({ name: "GssiAdminAccounts" });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
