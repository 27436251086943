
import { companies, IAssociate, IGroup } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";
import UserIcon from "../common/UserIcon.vue";
import OwnershipTransferConflictDialog from "./OwnershipTransferConflictDialog.vue";
import OwnershipTransferSuccessDialog from "./OwnershipTransferSuccessDialog.vue";

export default Vue.extend({
  components: {
    UserIcon,
    OwnershipTransferSuccessDialog,
    OwnershipTransferConflictDialog,
  },
  name: "OwnershipTransferDialog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      newOwner: {} as IAssociate,
      ownershipTransferSuccessDialog: false,
      text: [] as string[],
      ownershipTransferConflictDialog: false,
    };
  },
  computed: {
    associates: function (): IGroup[] {
      return this.$store.state.company?.associates;
    },
  },
  created() {
    this.text = [
      "Once you transfer this account, there's no going back. All",
      "owner permissions and billing responsibilities will be",
      "transferred after new owner completes the process. If you",
      "are are certain select a new owner below:",
    ];
    if ((this as any).isGssiAdmin) {
      this.text[2] =
        "transferred immediately. If you are are certain select a new ";
      this.text[3] = "owner below:";
    }
  },
  methods: {
    async submit() {
      if (!this.newOwner.id) {
        return;
      }

      let apicall = {} as any;
      if ((this as any).isGssiAdmin) {
        apicall = companies.company.transferOwnershipApproval;
      } else {
        apicall = companies.company.transferOwnershipRequest;
      }

      try {
        await apicall((this as any).companyId, this.newOwner.id);
        this.$store.dispatch(Actions.GetCompanyAccount);
        this.$store.dispatch(Actions.GetCompanyAssociates);
        this.ownershipTransferSuccessDialog = true;
      } catch (e: any) {
        // transfer conflict w/ gssi admin
        if ((this as any).isGssiAdmin && e?.response?.status === 4006) {
          this.ownershipTransferConflictDialog = true;
        } else {
          this.$store.dispatch(Actions.DisplayError, e);
        }
      }
    },
    close() {
      this.newOwner = {} as IAssociate;
      this.$emit("update:dialog", false);
    },
  },
  watch: {
    ownershipTransferSuccessDialog() {
      if (!this.ownershipTransferSuccessDialog) {
        this.close();
      }
    },
  },
});
