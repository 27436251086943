
import Vue from "vue";

export default Vue.extend({
  name: "CompanyHeader",
  props: {
    text: String,
    routePath: String,
  },
  methods: {
    navigate() {
      if (this.routePath) {
        this.$router.push({ path: this.routePath });
      } else {
        this.$router.go(-1);
      }
    },
  },
});
