
import {
  associates,
  companies,
  GssiRoles,
  IAssociate,
  IGroup,
} from "@/api";
import { Actions } from "@/store/models";
import { ASSOCIATE_ROLES, AvatarColors, deepCopy, getAvatarColor, getInitials, GSSI_USER_ROLES } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "GssiUserDialog",
  props: {
    dialog: Boolean,
    selectedUser: Object,
  },
  data() {
    return {
      user: {} as IAssociate,
      roles: GSSI_USER_ROLES,
      selectedRoles: [],
      selectedGroups: [] as number[],
      emailAlreadyExists: false,
    };
  },
  computed: {
    groups(): IGroup[] {
      return this.$store.state.company.groups;
    },
    header(): string {
      if (this.user.id) {
        return `Edit ${this.selectedUser.first_name} ${this.selectedUser.last_name}`;
      }
      return "Invite GSSI User";
    },
  },
  created() {
    this.setUser();
  },
  methods: {
    disablePermissionEdit(role: { value: GssiRoles }): boolean {
      return false
      // return (
      //   role.value === GssiRoles.ManageUsers &&
      //   this.$store.state.associate.id === this.user.id
      // );
    },
    setUser(): IAssociate {
      this.user = {
        first_name: "",
        last_name: "",
        user_name: "",
        roles: [] as any,
      } as IAssociate;
      return this.user;
    },
    async submit() {
      if (!this.user.first_name || !this.user.last_name || !this.user.user_name) {
        this.$store.dispatch(Actions.DisplayError, 'First Name, Last Name and Email Address are all required.')
        return
      }

      try {
        if (this.user.id) {
          await associates.update(this.user.id, {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            middle_name: this.user.middle_name,
            user_name: this.user.user_name,
            roles: [...this.user.roles],
          } as any);
        } else {
          const response = await companies.nlc.verifyUserEmail(
            this.user.user_name
          );
          if (response.data.userExists) {
            this.emailAlreadyExists = true;
            return;
          }

          await associates.gssi.invite({
            ...this.user,
            // company: { id: this.$store.state.associate.company.id },
            // associate_groups: this.selectedGroups,
            profile_bg_color: getAvatarColor(),
            profile_bg_intial: getInitials(this.user)
          } as any);
        }

        this.setUser();
        this.close();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        await this.$store.dispatch(Actions.GetCompanyAssociates);
        await this.$store.dispatch(Actions.GetCompanyAccount)
      }
    },
    updateGroups(val: number[]) {
      this.selectedGroups = val;
    },
    close() {
      this.emailAlreadyExists = false;
      this.$emit("update:dialog", false);
      this.$emit("update:selectedUser", this.setUser());
    },
  },
  watch: {
    selectedUser: {
      deep: true,
      handler() {
        this.user = deepCopy(this.selectedUser);
      },
    },
  },
});
